"use strict";

/*!
  Template Name: Neykart: E-commerce HTML Template by @devjey_io
	Version: 1.0
	Author: DevJEY
 */

/*--------------------------------------------------------------
>>> TABLE OF CONTENTS:
----------------------------------------------------------------
1.  Loader
2.  Template Color LocalStorage
3.  Show & Hide Menu
4.  Main Category Mega-menu
5.  Remove item in mini-cart
6.  Mobile Header Sticky
7.  Sidebar Open & Close
8.  Active Color - Mobile Navigation
9.  Mobile Search Overlay
10. New Arrival Product Filter
11. Open sidebar Color Picker
12. Open Modals - All Modals
    a. Choose template Color
    b. Target All Overlay Background
13. Product Image Gallery
14. Modal - Product Image Gallery
15. Accordion
16. Orders & Purchase Tabs
    a. Open Default Active Orders Tab
17. Show & Hide Password & Security Code
18. Show/Hide Same as Shipping Address
19. Copy to Clipboard
20. Footer Get Full Year
21. Scroll Back to Top
-------------------------------------------------------------- */

/** -- 1. Loader - Start */
document.onreadystatechange = function () {
  var pageLoader = document.getElementById("loader");
  var documentBody = document.querySelector("body");

  if (document.readyState == "complete") {
    pageLoader.classList.add("hidden", "animate__animated", "animate__fadeOut");
    documentBody.classList.add("animate__animated", "animate__fadeIn");
  }
};
/** -- 1. Loader - End */

/** -- 2. Template Color LocalStorage -- Start --  */


var swatchWidget = function () {
  var init,
      swatchWrap,
      checkStorage,
      setDataAttr,
      currentDataAttr,
      bodyElement = document.querySelector("body");
  swatchWrap = document.getElementById("theme-color");

  init = function init() {
    checkStorage(window.localStorage.getItem("dataAttr"));

    if (swatchWrap !== null) {
      swatchWrap.addEventListener("click", setDataAttr);
    }
  };

  checkStorage = function checkStorage(storage) {
    if (storage === null) {
      return;
    } else {
      bodyElement.classList.add(window.localStorage.getItem("dataAttr"));
    }
  };

  setDataAttr = function setDataAttr(event) {
    event.preventDefault();
    console.log("DEBUG: running setDataAttr"); // Get data attr

    currentDataAttr = event.target.getAttribute("data-color"); // Check for a match of class names

    if (currentDataAttr != window.localStorage.getItem("dataAttr")) {
      // Remove previous data attr
      bodyElement.classList.remove(window.localStorage.getItem("dataAttr"));
    } // Add data attr as class


    bodyElement.classList.add(currentDataAttr); // Set currentAttr to localStorage

    window.localStorage.setItem("dataAttr", currentDataAttr);
  };

  return {
    init: init
  };
}();

swatchWidget.init();
/** -- 2. Template Color LocalStorage -- End --  */

/** -- 3. Show & Hide Menu Navigation Dropdown Content -- Start */

var dropdown_nav = document.querySelectorAll("[data-dropdown]");
dropdown_nav.forEach(function (drop_menu) {
  drop_menu.addEventListener("mouseover", function () {
    document.querySelector(drop_menu.dataset.dropdown).style.animation = "downOut 450ms ease-in-out";
  });
  drop_menu.addEventListener("mouseleave", function () {
    document.querySelector(drop_menu.dataset.dropdown).style.animation = "downIn 450ms ease-in-out";
  });
});
/** -- 3. Show & Hide Menu Navigation Dropdown Content -- End */

/** -- 4. Main Category Mega-menu -- Start  */

var openCategoryMenu = function openCategoryMenu(cat, subCategory) {
  // Declare all variables
  var i, subCategoryContent, subCategoryLink; // Get all elements with class="nk-na-category" and hide them

  subCategoryContent = document.getElementsByClassName("nk-na-category");

  for (i = 0; i < subCategoryContent.length; i++) {
    subCategoryContent[i].style.display = "none";
  } // Get all elements with class="nk-na-cat-link" and remove the class "active-dep"


  subCategoryLink = document.getElementsByClassName("nk-na-cat-link");

  for (i = 0; i < subCategoryLink.length; i++) {
    subCategoryLink[i].className = subCategoryLink[i].className.replace(" active-dep", "");
  } // Show the current tab, and add an "active-dep" class to the button that opened the tab


  document.getElementById(subCategory).style.display = "block";
  cat.currentTarget.className += " active-dep";
};
/** -- 4. Main Category Mega-menu -- End  */

/** -- 5. Remove item in mini-cart -- Start */


var miniCartBag = document.querySelector(".nk-mini-cart-section");

if (miniCartBag !== null) {
  var remove_item = miniCartBag.querySelectorAll(".nk-delete-item");
  remove_item.forEach(function (item) {
    item.addEventListener("click", function () {
      this.closest(".nk-minicart-item-container").style.display = "none";
    });
  });
}
/** -- 5. Remove item in mini-cart -- End */

/** -- 6. Mobile Header Sticky - Start -- */
// Get the header


var mobileHeader = document.getElementById("stickyHeader");

if (mobileHeader !== null) {
  // Add the sticky class to the header when you reach its scroll position. Remove "nk-sticky-nav" when you leave the scroll position
  var mobileStickyHeader = function mobileStickyHeader() {
    if (window.pageYOffset > sticky) {
      mobileHeader.classList.add("nk-sticky-nav");
    } else {
      mobileHeader.classList.remove("nk-sticky-nav");
    }
  }; // When the user scrolls the page, execute mobileStickyHeader


  // Get the offset position of the navbar
  var sticky = mobileHeader.offsetTop;
  window.addEventListener("scroll", mobileStickyHeader);
}
/** -- 6. Mobile Header Sticky - End -- */

/** -- 7. Sidebar Open & Close - Small device & Laptops with <= 1279px | Menu - Start */


var overlay = document.getElementById("overlay"); // Global Const

var bodyDocument = document.body; // Global Const

var open_sidebars = document.querySelectorAll("[data-open-sidebar]");
var close_sidebars = document.querySelectorAll("[data-close-sidebar]");
open_sidebars.forEach(function (sidebar) {
  sidebar.addEventListener("click", function (e) {
    e.preventDefault();
    var sidebar_target = document.querySelector(sidebar.dataset.openSidebar);
    sidebar_target.style.transition = "0.5s";
    sidebar_target.style.transform = "translateX(0%)";
    overlay.classList.remove("hidden");
    bodyDocument.style.overflowY = "hidden";
  });
});
close_sidebars.forEach(function (sidebar) {
  sidebar.addEventListener("click", function () {
    var sidebar_target = document.querySelector(sidebar.dataset.closeSidebar);
    sidebar_target.style.transform = "translateX(-100%)";
    overlay.classList.add("hidden");
    bodyDocument.style.overflowY = "auto";
  });
});
/** -- 7. Sidebar Open & Close -  Small device & Laptops with =< 1279px | Menu - End */

/** -- 8. Add active color to the bottom Sticky Navigation - Start -- */

var site_url = window.location.href;
var sticky_home = document.getElementById("mob-sticky-home");
var sticky_wishlist = document.getElementById("mob-sticky-wishlist");
var sticky_cart = document.getElementById("mob-sticky-cart");
var sticky_account = document.getElementById("mob-sticky-account");
var sticky_more = document.getElementById("mob-sticky-more");
var home_url = "";
var wishlist_url = "account-wishlist.html";
var cart_url = "cart.html";
var account_url = "account-overview.html";
var more_url = "more";
var active_classes = ["font-semibold", "text-primary", "transform", "duration-300", "ease-in-out"];
var url_part = site_url.split("/");

function check_url_part(active_id, active_url) {
  if (active_id !== null && active_url === url_part[url_part.length - 1]) {
    var _active_id$classList;

    (_active_id$classList = active_id.classList).add.apply(_active_id$classList, active_classes);

    return;
  }
}

check_url_part(sticky_home, home_url);
check_url_part(sticky_wishlist, wishlist_url);
check_url_part(sticky_cart, cart_url);
check_url_part(sticky_account, account_url);
/** -- 8. Add active color to the bottom Sticky Navigation - End -- */

/** -- 9. Mobile Search Overlay - Start --  */

var open_search_overlay = document.getElementById("open-search-overlay");
var search_overlay = document.getElementById("mobile-search-overlay");
var close_search_overlay = document.getElementById("close-search");

if (open_search_overlay !== null) {
  open_search_overlay.addEventListener("click", function () {
    search_overlay.classList.remove("hidden");
    search_overlay.classList.add("nk-bg-overlay");
    bodyDocument.classList.add("overflow-y-hidden"); // bodyDocument.style.overflowY = "hidden";
  });
  close_search_overlay.addEventListener("click", function () {
    search_overlay.classList.add("hidden");
    search_overlay.classList.remove("nk-bg-overlay");
    bodyDocument.classList.remove("overflow-y-hidden"); // bodyDocument.style.overflowY = "auto";
  });
}
/** -- 9. Mobile Search Overlay - End --  */

/** -- 10. New Arrival Product Filter - Start --  */


var btn_filter = document.querySelectorAll("[data-filter-link]");
var products = document.querySelectorAll("[data-filter]");

if (btn_filter !== null) {
  var filterButtons = function filterButtons(e) {
    var targetBtn = e.target.textContent.toLowerCase();
    targetBtn === "all" ? products.forEach(function (p) {
      p.classList.contains("hidden") ? p.classList.remove("hidden") : null;
    }) : products.forEach(function (p) {
      p.classList.add("hidden");
      p.dataset.filter === targetBtn ? p.classList.remove("hidden") : null;
    });
  };

  btn_filter.forEach(function (filterLink) {
    filterLink.addEventListener("click", filterButtons);
  });
  var arrival_tabs = document.getElementById("new-arrival-tabs");

  if (arrival_tabs !== null) {
    var newLinks = btn_filter;

    for (var i = 0; i < (newLinks === null || newLinks === void 0 ? void 0 : newLinks.length); i++) {
      newLinks[i].addEventListener("click", function () {
        var currentLink = document.getElementsByClassName("new-active");
        currentLink[0].className = currentLink[0].className.replace("new-active", " ");
        this.className += " new-active";
      });
    }
  }
}
/** -- 10. New Arrival Product Filter - End --  */

/** -- 11. Open sidebar Color Picker - Start */


var openColorPicker = document.getElementById("color-section");

var openColorPick = function openColorPick() {
  openColorPicker.style.transition = "0.5s";
  openColorPicker.style.transform = "translateX(0%)";
  overlay.classList.remove("hidden");
  bodyDocument.style.overflowY = "hidden";
};

var closeColorPick = function closeColorPick() {
  openColorPicker.style.transform = "translateX(89%)";
  overlay.classList.add("hidden");
  bodyDocument.style.overflowY = "auto";
};
/** -- 11. Open sidebar Color Picker - End */

/** -- 12. Open Modals - All Modals -- Start */


window.onload = function () {
  var btns = document.querySelectorAll("[data-target-modal]");
  var close_modals = document.querySelectorAll(".nk-na-close-modal");
  btns.forEach(function (btn) {
    btn.addEventListener("click", function () {
      document.querySelector(btn.dataset.targetModal).classList.remove("hidden"); // document.querySelector(btn.dataset.targetModal).classList.add("animate__animated", "animate__fadeIn");

      overlay.classList.remove("hidden"); // Locate at the Top const overlay = document.getElementById("overlay");

      overlay.classList.add("animate__animated", "animate__fadeIn");
      bodyDocument.style.overflowY = "hidden"; // Locate at the Top const overlay = document.getElementById("overlay");
    });
  });
  close_modals.forEach(function (btn) {
    btn.addEventListener("click", function () {
      var modal = btn.closest(".nk-na-open-modal");
      modal.classList.add("hidden");
      overlay.classList.add("hidden");
      bodyDocument.style.overflowY = "auto";
    });
  });
  /** -- 12.a. Choose template Color -- Start --  */

  var changeColor = document.querySelectorAll("[data-theme-color]");
  var mainThemeColor = document.querySelector("#theme");
  var defaultColor = document.querySelector("#btn-def-mode");
  var blueThemeColor = document.querySelector("#btn-blue-mode");
  var redThemeColor = document.querySelector("#btn-red-mode");
  var greenThemeColor = document.querySelector("#btn-green-mode");
  var orangeThemeColor = document.querySelector("#btn-orange-mode");
  var brownThemeColor = document.querySelector("#btn-brown-mode");
  var purpleThemeColor = document.querySelector("#btn-purple-mode");
  var aquaThemeColor = document.querySelector("#btn-aqua-mode");
  var grayThemeColor = document.querySelector("#btn-gray-mode");
  var cyanThemeColor = document.querySelector("#btn-cyan-mode");
  changeColor.forEach(function (btnColor) {
    if (btnColor !== null) {
      btnColor.addEventListener("click", function () {
        if (btnColor === defaultColor) {
          mainThemeColor.classList.remove("theme-blue", "theme-red", "theme-green", "theme-orange", "theme-brown", "theme-purple", "theme-aqua", "theme-gray", "theme-cyan");
        } else if (btnColor === blueThemeColor) {
          mainThemeColor.classList.add("theme-blue");
          mainThemeColor.classList.remove("theme-red", "theme-green", "theme-orange", "theme-brown", "theme-purple", "theme-aqua", "theme-gray", "theme-cyan");
        } else if (btnColor === redThemeColor) {
          mainThemeColor.classList.add("theme-red");
          mainThemeColor.classList.remove("theme-blue", "theme-green", "theme-orange", "theme-brown", "theme-purple", "theme-aqua", "theme-gray", "theme-cyan");
        } else if (btnColor === greenThemeColor) {
          mainThemeColor.classList.add("theme-green");
          mainThemeColor.classList.remove("theme-blue", "theme-red", "theme-orange", "theme-brown", "theme-purple", "theme-aqua", "theme-gray", "theme-cyan");
        } else if (btnColor === orangeThemeColor) {
          mainThemeColor.classList.add("theme-orange");
          mainThemeColor.classList.remove("theme-blue", "theme-red", "theme-green", "theme-brown", "theme-purple", "theme-aqua", "theme-gray", "theme-cyan");
        } else if (btnColor === brownThemeColor) {
          mainThemeColor.classList.add("theme-brown");
          mainThemeColor.classList.remove("theme-blue", "theme-red", "theme-green", "theme-orange", "theme-purple", "theme-aqua", "theme-gray", "theme-cyan");
        } else if (btnColor === purpleThemeColor) {
          mainThemeColor.classList.add("theme-purple");
          mainThemeColor.classList.remove("theme-blue", "theme-red", "theme-green", "theme-orange", "theme-brown", "theme-aqua", "theme-gray", "theme-cyan");
        } else if (btnColor === aquaThemeColor) {
          mainThemeColor.classList.add("theme-aqua");
          mainThemeColor.classList.remove("theme-blue", "theme-red", "theme-green", "theme-orange", "theme-brown", "theme-purple", "theme-gray", "theme-cyan");
        } else if (btnColor === grayThemeColor) {
          mainThemeColor.classList.add("theme-gray");
          mainThemeColor.classList.remove("theme-blue", "theme-red", "theme-green", "theme-orange", "theme-brown", "theme-purple", "theme-aqua", "theme-cyan");
        } else if (btnColor === cyanThemeColor) {
          mainThemeColor.classList.add("theme-cyan");
          mainThemeColor.classList.remove("theme-blue", "theme-red", "theme-green", "theme-orange", "theme-brown", "theme-purple", "theme-aqua", "theme-gray");
        }
      });
    }
  });
  /** -- 12.a. Choose template Color -- End --  */

  /** -- 12.b. Target All Overlay Background - Start */

  window.addEventListener("click", function (event) {
    if (event.target == overlay) {
      /** -- Close All modal menu - Start */
      var modals = document.querySelectorAll(".nk-na-open-modal");
      modals.forEach(function (modal) {
        return modal.classList.add("hidden");
      });
      overlay.classList.add("hidden");
      bodyDocument.style.overflowY = "auto";
      /** -- Close All modal menu - End */

      /** -- Mobile Menu & Product Filter - Start */

      var slide_mobile_menu = document.getElementById("slide-mobile-menu");
      var product_filter = document.getElementById("product-filter");
      var filter_width = window.innerWidth;
      var min_filter_width = 767;
      slide_mobile_menu.style.transform = "translateX(-100%)";

      if (product_filter !== null && filter_width <= min_filter_width) {
        product_filter.style.transform = "translateX(-100%)";
      }

      console.log(filter_width);
      /** -- Mobile Menu & Product Filter - End */

      /** -- Close mobile Menu - Start */

      openColorPicker.style.transform = "translateX(89%)";
      /** Close mobile Menu - End */
    }
  });
  /** -- 12.b. Target All Overlay Background - End */
};
/** -- 12. Open Modals - All Modals -- End */

/** -- 13. Product Image Gallery -- Start -- */


var viewPhoto = function viewPhoto(imgs) {
  var clickedImg = document.getElementById("clickedProductImg");
  clickedImg.src = imgs.src;
  clickedImg.parentElement.style.display = "block";
};
/** -- 13. Product Image Gallery -- End -- */

/** -- 14. Modal - Product Image Gallery -- Start -- */


var viewPhotoModal = function viewPhotoModal(imgsModal) {
  var clickedImg = document.getElementById("clickedProductImgModal");
  clickedImg.src = imgsModal.src;
  clickedImg.parentElement.style.display = "block";
};

var productModal = document.getElementById("productModalGallery");
var magnifyPlus = document.getElementById("zoomProductPlus");
var zoomMinus = document.getElementById("zoomProductMinus");

if (magnifyPlus && zoomMinus !== null) {
  magnifyPlus.onclick = function () {
    productModal.style.display = "block";
  };

  zoomMinus.onclick = function () {
    productModal.style.display = "none";
  };
}

window.onclick = function (event) {
  if (event.target == productModal) {
    productModal.style.display = "none";
  }
};
/** -- 14. Modal - Product Image Gallery -- End -- */

/** -- 15. Accordion Section -- Start --  */


var itemDescription = document.getElementsByClassName("nk-item-accordion");

for (var _i = 0; _i < itemDescription.length; _i++) {
  itemDescription[_i].addEventListener("click", function () {
    this.classList.toggle("nk-active-item-acc");
    var itemInfo = this.nextElementSibling;

    if (itemInfo.style.maxHeight) {
      itemInfo.style.maxHeight = null;
    } else {
      itemInfo.style.maxHeight = itemInfo.scrollHeight + "px";
    }
  });
}
/** -- 15. Accordion Section -- End --  */

/** -- 16. Orders & Purchase Tabs -- Start  */

/** NOTE: op - Stands for Order & Purchase */


var openOPTabs = function openOPTabs(evt, opTabName) {
  // Declare all variables
  var i, opTabContent, optablinks; // Get all elements with class="opTabContent" and hide them

  opTabContent = document.getElementsByClassName("nk-na-tabContent"); // nk-na-department

  for (i = 0; i < opTabContent.length; i++) {
    opTabContent[i].style.display = "none";
  } // Get all elements with class="optablinks" and remove the class "nk-acc-active-tab"


  optablinks = document.getElementsByClassName("nk-na-optablinks");

  for (i = 0; i < optablinks.length; i++) {
    optablinks[i].className = optablinks[i].className.replace("nk-acc-active-tab", "nk-account-tab");
  } // Show the current tab, and add an "nk-acc-active-tab" class to the button that opened the tab


  document.getElementById(opTabName).style.display = "block";
  evt.currentTarget.className += " nk-acc-active-tab";
  evt.currentTarget.classList.remove("nk-account-tab");
};
/** -- 16.a. Open Default Active Orders Tab after loading - Start */


var orderPurchaseOpenTab = document.querySelector("#default-tab"); // Open Default Tab;

if (orderPurchaseOpenTab !== null) {
  orderPurchaseOpenTab.click();
}
/** -- 16.a. Open Default Active Orders Tab after loading - End */

/** -- 16. Orders & Purchase Tabs -- End */

/** -- 17. Show & Hide Password & Security Code Character -- Start */


var showHide = function showHide() {
  var toggleShowHide = document.querySelectorAll("[data-password-show-hide]");
  toggleShowHide.forEach(function (toggleClick) {
    toggleClick.addEventListener("click", function () {
      var inputToggle = document.querySelector(toggleClick.dataset.passwordShowHide);

      if (toggleClick.innerText === "Show" && inputToggle.type === "password") {
        toggleClick.innerText = "Hide";
        inputToggle.type = "text";
      } else {
        toggleClick.innerText = "Show";
        inputToggle.type = "password";
      }

      var confirmPassword = document.querySelector("#confirm-password");

      if (confirmPassword !== null) {
        var confirm_password = function confirm_password() {
          if (confirmPassword.type === "password") {
            confirmPassword.type = "text";
          } else {
            confirmPassword.type = "password";
          }
        };

        confirm_password();
      }
    });
  });
};

showHide();
/** -- 17. Show & Hide Password & Security Code Character -- Start */

/** -- 18. Show/Hide Same as Shipping Address -- Start */

var hideShowBillingDetails = function hideShowBillingDetails() {
  var checkBox = document.getElementById("sameShippingAddress");
  var billingDetails = document.getElementById("billingAddress");

  if (checkBox.checked == false) {
    billingDetails.style.display = "block";
  } else {
    billingDetails.style.display = "none";
  }
};
/** -- 18. Show/Hide Same as Shipping Address -- End */

/** -- 19. Copy to Clipboard -- Start --  */


var copyButton = document.querySelectorAll("[data-copy-div]");
copyButton.forEach(function (copy) {
  copy.addEventListener("click", function () {
    var ElementDiv = document.querySelector(copy.dataset.copyDiv);
    DOMPurify.sanitize(copyText(ElementDiv));
    copy.innerText = "Copied";
    setTimeout(function () {
      copy.innerText = "Copy";
    }, 1300);
  });
}); // Main functionality

function copyText(element) {
  var elementToCopy = element.innerHTML; // create a temporary box, and stop the viewport scrolling

  var tempTextareaElement = document.createElement("textarea");
  tempTextareaElement.value = elementToCopy;
  document.body.appendChild(tempTextareaElement);
  tempTextareaElement.style.top = "0";
  tempTextareaElement.style.left = "0";
  tempTextareaElement.style.position = "fixed"; // select the text in the box and copy it

  tempTextareaElement.focus();
  tempTextareaElement.setSelectionRange(0, 99999);
  tempTextareaElement.select();
  document.execCommand("Copy");
  document.body.removeChild(tempTextareaElement);
}
/** -- 19. Copy to Clipboard -- End --  */

/** -- 20. Footer Get Full Year -- Start --  */


var footer_current_year = document.getElementById("copyright-year");
var current_year = new Date();

if (footer_current_year !== null) {
  footer_current_year.innerHTML = current_year.getFullYear();
}
/** -- 20. Footer Get Full Year -- End --  */

/** -- 21. Scroll Back to Top - Start */
//Get the button


var backToTopButton = document.querySelector(".nk-scroll-to-top"); // When the user scrolls down 100px from the top of the document, show the button

if (backToTopButton !== null) {
  var scrollFunction = function scrollFunction() {
    if (document.body.scrollTop > 100 || document.documentElement.scrollTop > 100) {
      backToTopButton.style.display = "block";
    } else {
      backToTopButton.style.display = "none";
    }
  }; // When the user clicks on the button, scroll to the top of the document


  var topFunction = function topFunction() {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
  };

  window.addEventListener("scroll", scrollFunction);
  backToTopButton.addEventListener("click", topFunction);
}
/** -- 21. Scroll Back to Top - End */