/*!
  Template Name: Neykart: E-commerce HTML Template by @devjey_io
	Version: 1.0
	Author: DevJEY
 */

/*--------------------------------------------------------------
>>> TABLE OF CONTENTS:
----------------------------------------------------------------
1. Customer Trust
2. Brands
-------------------------------------------------------------- */
"use strict"; // FETCH LOCAL .JSON DATA

function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _unsupportedIterableToArray(arr, i) || _nonIterableRest(); }

function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

function _iterableToArrayLimit(arr, i) { var _i = arr == null ? null : typeof Symbol !== "undefined" && arr[Symbol.iterator] || arr["@@iterator"]; if (_i == null) return; var _arr = []; var _n = true; var _d = false; var _s, _e; try { for (_i = _i.call(arr); !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"] != null) _i["return"](); } finally { if (_d) throw _e; } } return _arr; }

function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }

var allCustomerTrust = fetch("./assets/data/home/customerTrust.json");
var allbrands = fetch("./assets/data/home/brands.json");
Promise.all([allCustomerTrust, allbrands]).then(function (values) {
  return Promise.all(values.map(function (r) {
    return r.json();
  }));
}).then(function (_ref) {
  var _ref2 = _slicedToArray(_ref, 2),
      allCustomerTrust = _ref2[0],
      brands = _ref2[1];

  //console.log(allCustomerTrust);
  //console.log(brands);

  /** 1. Customer Trust Section - Start */
  var cTrust = document.getElementById("customerTrust");

  if (cTrust !== null) {
    cTrust.innerHTML = DOMPurify.sanitize("\n          ".concat(allCustomerTrust.map(function (customerTrust) {
      return "\n              <div class=\"W-1/4 xl:w-1/4 flex-grow flex-shrink-0 md:flex-shrink md:flex-grow-0\">\n                <div class=\"text-sm flex lg:flex-row space-x-3 lg:items-center\">\n                  <span class=\"block lg:inline-block text-3xl text-primary\">\n                    ".concat(customerTrust.trustSvg, "\n                  </span>\n                  <span class=\"whitespace-nowrap\">\n                    <h1 class=\"lg:text-sm font-semibold text-gray-900\">").concat(customerTrust.trustHead, "</h1>\n                    <p class=\"text-sm text-gray-500\">").concat(customerTrust.trustDetail, "</p>\n                  </span>\n                </div>              \n              </div>\n            ");
    }).join(""), "\n        "));
  }
  /** 1. Customer Trust Section - End */

  /** 2. Brands Section - Start */


  var brandList = document.querySelector(".brands");

  if (brandList !== null) {
    brandList.innerHTML = DOMPurify.sanitize("\n       ".concat(brands.map(function (brands) {
      return "\n              <div class=\"w-28\">\n                <div class=\"px-3 py-2 h-10 w-28 border border-gray-600 rounded-sm bg-white cursor-pointer transform duration-300 ease-in-out hover:-translate-y-1 hover:shadow-lg\">\n                    <a href=\"".concat(brands.brandURL, "\">\n                        <img class=\"h-full w-full object-center\" src=\"").concat(brands.brandSvg, "\" alt=\"\" />\n                    </a>\n                </div>\n              </div>\n            ");
    }).join(""), "\n      "));
  }
  /** 2. Brands Section - End */
  //document.getElementById("").innerHTML = DOMPurify.sanitize(``);
  // END CURL BRACES

});