"use strict";

/*!
  Template Name: Neykart: E-commerce HTML Template by @devjey_io
	Version: 1.0
	Author: DevJEY
 */

/*--------------------------------------------------------------
>>> TABLE OF CONTENTS:
----------------------------------------------------------------
0. Hero Sliders
1. New Arrival Swiper Slides
2. Top Categories Slides
3. Best Seller Slides
4. Instagram Shop - Fashion Slides
5. Customer Also Bought
6. Our Recommendation
7. Instagram Shop - Furniture Slides
8. New Arrival - Grocery Slides
-------------------------------------------------------------- */
function initAllSwiper() {
  /* ===== 0. Hero Sliders == Start */
  var heroSlides = document.querySelector(".nk-hero-slider");

  if (heroSlides !== null) {
    var heroSlides = new Swiper(".nk-hero-slider", {
      slidesPerView: 1,
      spaceBetween: 0,
      // slidesPerGroup: 3,
      // Optional parameters
      direction: "horizontal",
      loop: true,
      loopFillGroupWithBlank: true,
      // AutoPlay
      autoplay: {
        delay: 1500,
        disableOnInteraction: false,
        pauseOnMouseEnter: true
      },
      speed: 2000,
      // If we need pagination
      pagination: {
        el: ".nk-swiper-hero-pag",
        clickable: true
      } // Navigation arrows
      // navigation: { nextEl: ".swiper-button-next", prevEl: ".swiper-button-prev" },

    });
  }
  /* ===== 0. Hero Sliders == End */

  /* ===== 1. New Arrival Slides == Start */


  var newArrival = document.querySelector(".nk-new-swiper");

  if (newArrival !== null) {
    var newArrival = new Swiper(".nk-new-swiper", {
      slidesPerView: 2,
      spaceBetween: 10,
      // slidesPerGroup: 3,
      // Optional parameters
      direction: "horizontal",
      loop: false,
      loopFillGroupWithBlank: true,
      // AutoPlay
      // autoplay: { delay: 2500, disableOnInteraction: false },
      // If we need pagination
      pagination: {
        el: ".nk-swiper-new-pag",
        clickable: true
      },
      // Breakpoint
      breakpoints: {
        375: {
          slidesPerView: 2,
          spaceBetween: 10
        },
        567: {
          slidesPerView: 3,
          spaceBetween: 15
        },
        768: {
          slidesPerView: 4,
          spaceBetween: 15
        },
        1280: {
          slidesPerView: 5,
          spaceBetween: 20
        },
        1366: {
          slidesPerView: 6,
          spaceBetween: 10
        }
      } // Navigation arrows
      // navigation: { nextEl: ".swiper-button-next", prevEl: ".swiper-button-prev" },

    });
  }
  /* ===== 1. New Arrival Slides == End */

  /* ===== 2. Top Categories Slides == Start */


  var categSwiper = document.querySelector(".nk-categ-swiper");

  if (categSwiper !== null) {
    var categSwiper = new Swiper(".nk-categ-swiper", {
      slidesPerView: 2,
      spaceBetween: 10,
      // slidesPerGroup: 3,
      // Optional parameters
      direction: "horizontal",
      loop: false,
      loopFillGroupWithBlank: true,
      // AutoPlay
      // autoplay: { delay: 2500, disableOnInteraction: false },
      // If we need pagination
      pagination: {
        el: ".nk-swiper-categ-pag",
        clickable: true
      },
      // Breakpoint
      breakpoints: {
        // 375: { slidesPerView: 3, spaceBetween: 10 },
        567: {
          slidesPerView: 4,
          spaceBetween: 15
        },
        768: {
          slidesPerView: 5,
          spaceBetween: 15
        },
        1280: {
          slidesPerView: 6,
          spaceBetween: 20
        }
      } // Navigation arrows
      // navigation: { nextEl: ".swiper-button-next", prevEl: ".swiper-button-prev" },

    });
  }
  /* ===== 2. Top Categories Slides == End */

  /* ===== 3. Best Sellers Slides == Start */


  var sellerSwiper = document.querySelector(".nk-best-seller-swiper");

  if (sellerSwiper !== null) {
    var sellerSwiper = new Swiper(".nk-best-seller-swiper", {
      slidesPerView: 1,
      spaceBetween: 10,
      // slidesPerGroup: 3,
      // Optional parameters
      direction: "horizontal",
      loop: false,
      loopFillGroupWithBlank: true,
      // AutoPlay
      // autoplay: { delay: 2500, disableOnInteraction: false },
      // If we need pagination
      pagination: {
        el: ".nk-swiper-seller-pag",
        clickable: true
      },
      // Breakpoint
      breakpoints: {
        375: {
          slidesPerView: 2,
          spaceBetween: 10
        },
        567: {
          slidesPerView: 3,
          spaceBetween: 15
        },
        768: {
          slidesPerView: 4,
          spaceBetween: 15
        },
        1024: {
          slidesPerView: 4,
          spaceBetween: 30
        },
        1280: {
          slidesPerView: 5,
          spaceBetween: 20
        }
      } // Navigation arrows
      // navigation: { nextEl: ".swiper-button-next", prevEl: ".swiper-button-prev" },

    });
  }
  /* ===== 3. Best Sellers Slides == End */

  /* ===== 4. Instagram Shop - Fashion Slides == Start */


  var instaShopSwiper = document.querySelector(".nk-insta-swiper");

  if (instaShopSwiper !== null) {
    var instaShopSwiper = new Swiper(".nk-insta-swiper", {
      slidesPerView: 1,
      spaceBetween: 10,
      // slidesPerGroup: 3,
      // Optional parameters
      direction: "horizontal",
      loop: false,
      loopFillGroupWithBlank: true,
      // AutoPlay
      // autoplay: { delay: 2500, disableOnInteraction: false },
      // If we need pagination
      pagination: {
        el: ".nk-swiper-insta-pag",
        clickable: true
      },
      // Breakpoint
      breakpoints: {
        429: {
          slidesPerView: 2,
          spaceBetween: 10
        },
        567: {
          slidesPerView: 2,
          spaceBetween: 20
        },
        768: {
          slidesPerView: 3,
          spaceBetween: 20
        },
        1024: {
          slidesPerView: 3,
          spaceBetween: 40
        },
        1280: {
          slidesPerView: 4,
          spaceBetween: 40
        }
      } // Navigation arrows
      // navigation: { nextEl: ".swiper-button-next", prevEl: ".swiper-button-prev" },

    });
  }
  /* ===== 4. Instagram Shop - Fashion Slides == End */

  /* ===== 5. Customer Also bought == Start */


  var boughtTogether = document.querySelector(".nk-bought-swiper");

  if (boughtTogether !== null) {
    var boughtTogether = new Swiper(".nk-bought-swiper", {
      slidesPerView: 1,
      spaceBetween: 30,
      // slidesPerGroup: 3,
      // Optional parameters
      direction: "horizontal",
      loop: false,
      loopFillGroupWithBlank: true,
      // AutoPlay
      // autoplay: { delay: 2500, disableOnInteraction: false },
      // If we need pagination
      pagination: {
        el: ".nk-swiper-bought-pag",
        clickable: true
      },
      // Breakpoint
      breakpoints: {
        320: {
          slidesPerView: 2,
          spaceBetween: 10
        },
        375: {
          slidesPerView: 2,
          spaceBetween: 15
        },
        429: {
          slidesPerView: 2,
          spaceBetween: 40
        },
        555: {
          slidesPerView: 3,
          spaceBetween: 10
        },
        567: {
          slidesPerView: 3,
          spaceBetween: 30
        },
        768: {
          slidesPerView: 4,
          spaceBetween: 20
        },
        1024: {
          slidesPerView: 5,
          spaceBetween: 20
        },
        1280: {
          slidesPerView: 6,
          spaceBetween: 30
        }
      },
      // Navigation arrows
      navigation: {
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev"
      }
    });
  }
  /*===== 5. Customer Also bought == End */

  /*===== 6. Our Recommendation == Start */


  var boughtTogether = document.querySelector(".nk-recommends-swiper");

  if (boughtTogether !== null) {
    var boughtTogether = new Swiper(".nk-recommends-swiper", {
      slidesPerView: 1,
      spaceBetween: 30,
      // slidesPerGroup: 3,
      // Optional parameters
      direction: "horizontal",
      loop: false,
      loopFillGroupWithBlank: true,
      // AutoPlay
      // autoplay: { delay: 2500, disableOnInteraction: false },
      // If we need pagination
      pagination: {
        el: ".nk-swiper-bought-pag",
        clickable: true
      },
      // Breakpoint
      breakpoints: {
        320: {
          slidesPerView: 2,
          spaceBetween: 10
        },
        375: {
          slidesPerView: 2,
          spaceBetween: 15
        },
        429: {
          slidesPerView: 2,
          spaceBetween: 40
        },
        555: {
          slidesPerView: 3,
          spaceBetween: 10
        },
        567: {
          slidesPerView: 3,
          spaceBetween: 30
        },
        768: {
          slidesPerView: 4,
          spaceBetween: 10
        },
        1024: {
          slidesPerView: 5,
          spaceBetween: 10
        },
        1280: {
          slidesPerView: 6,
          spaceBetween: 10
        }
      },
      // Navigation arrows
      navigation: {
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev"
      }
    });
  }
  /*===== 6. Our Recommendation == End */

  /* ===== 7. Instagram Shop - Furniture Slides == Start */


  var instaShopSwiper = document.querySelector(".nk-insta-furniture-swiper");

  if (instaShopSwiper !== null) {
    var instaShopSwiper = new Swiper(".nk-insta-furniture-swiper", {
      slidesPerView: 1,
      spaceBetween: 10,
      // slidesPerGroup: 3,
      // Optional parameters
      direction: "horizontal",
      loop: false,
      loopFillGroupWithBlank: true,
      // AutoPlay
      // autoplay: { delay: 2500, disableOnInteraction: false },
      // If we need pagination
      pagination: {
        el: ".nk-swiper-insta-pag",
        clickable: true
      },
      // Breakpoint
      breakpoints: {
        429: {
          slidesPerView: 2,
          spaceBetween: 0
        },
        567: {
          slidesPerView: 2,
          spaceBetween: 0
        },
        768: {
          slidesPerView: 3,
          spaceBetween: 0
        },
        1024: {
          slidesPerView: 3,
          spaceBetween: 0
        },
        1280: {
          slidesPerView: 4,
          spaceBetween: 0
        }
      } // Navigation arrows
      // navigation: { nextEl: ".swiper-button-next", prevEl: ".swiper-button-prev" },

    });
  }
  /* ===== 7. Instagram Shop - Furniture Slides == End */

  /* ===== 8. Today Deal Grocery Slides == Start */


  var newArrival = document.querySelector(".nk-new-grocery-swiper");

  if (newArrival !== null) {
    var newArrival = new Swiper(".nk-new-grocery-swiper", {
      slidesPerView: 1,
      spaceBetween: 10,
      // slidesPerGroup: 3,
      // Optional parameters
      direction: "horizontal",
      loop: true,
      loopFillGroupWithBlank: true,
      // AutoPlay
      autoplay: {
        delay: 1500,
        disableOnInteraction: false,
        pauseOnMouseEnter: true
      },
      speed: 1000,
      // If we need pagination
      pagination: {
        el: ".nk-swiper-new-pag",
        clickable: true
      },
      // Breakpoint
      breakpoints: {
        320: {
          slidesPerView: 2,
          spaceBetween: 10
        },
        375: {
          slidesPerView: 2,
          spaceBetween: 15
        },
        429: {
          slidesPerView: 2,
          spaceBetween: 40
        },
        555: {
          slidesPerView: 3,
          spaceBetween: 10
        },
        567: {
          slidesPerView: 3,
          spaceBetween: 30
        },
        768: {
          slidesPerView: 4,
          spaceBetween: 15
        },
        1024: {
          slidesPerView: 5,
          spaceBetween: 15
        },
        1280: {
          slidesPerView: 6,
          spaceBetween: 20
        }
      },
      // Navigation arrows
      navigation: {
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev"
      }
    });
  }
  /* ===== 8. Today Deal Grocery Slides == End */

} // Initialize the function


initAllSwiper();